import { ThemeUICSSObject } from 'theme-ui';

export const title: ThemeUICSSObject = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: 2,
  img: {
    height: '50px',
    width: '50px',
  },
};
