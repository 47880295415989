import { ThemeUICSSObject } from '@apia/theme';

const menuLandingPage: ThemeUICSSObject = {
  'div ul.listbox__list': { gap: 0 },
  li: (theme) => {
    return {
      p: 3,
      '&:last-of-type': {
        border: 'none',
      },
      '&.activeTab': theme.palette.getStatesFromPath('buttons.primary'),
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: 3,
      '.listItem__img': {
        height: '40px',
        width: '40px',
        flexShrink: 0,
      },
      '.listItem__img__both': {
        height: '30px',
        width: '30px',
        flexShrink: 0,
      },
      '.listItem__title': {
        ml: 2,
      },
    };
  },
  '.menuLandingPageBtn': {
    backgroundColor: 'inherit',
    color: 'palette.text.primary',
    svg: {
      width: '24px',
      height: '24px',
    },
  },
};

export { menuLandingPage };
