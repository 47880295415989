import { ThemeUICSSObject } from '@apia/theme';
import { listbox } from './listbox';
import { tableList } from './tableList';
import { widgets } from './widgets';
import { charts } from './charts';

export const components: ThemeUICSSObject = {
  listbox,
  tableList,
  widgets,
  charts,
};
