import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const searchModal: ThemeUICSSObject = {
  /* PREPEND HERE */

  '&.modal__variantWrapper:not(.screenLock)': {
    boxShadow: 'none',
    height: 'auto',
    inset: '50px 50px auto 50px',
    position: 'fixed',
  },

  '.modal__content, .modal__main': {
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    p: 0,
  },

  '.modal__main': {
    height: 'auto',
  },

  '.modal__content': {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,

    '& > *': {
      boxShadow: 'modals',
    },
  },

  '.search__input': {
    padding: 6,
    fontSize: 20,
  },

  '.search__results': {
    background: 'palette.background.paper',
    border: '1px solid',
    borderColor: 'palette.border.section',

    gap: 6,
    padding: 6,

    display: 'flex',
    flexDirection: 'column',
  },

  '.search__results__currentSearch, .search__results__currentSearch *': {
    fontSize: 22,

    strong: {
      textDecoration: 'underline',
    },
  },

  '.search__results__item': (theme) => ({
    padding: 6,
    border: '1px solid',
    borderColor: 'palette.border.article',
    color: 'palette.text.primary',
    textDecoration: 'none',

    ...theme.palette.getStatesFromDefinition(
      {
        backgroundColor: theme.palette.background.paper,
      },
      {
        states: {
          active: false,
          checked: false,
          default: false,
          disabled: false,
          selected: false,
        },
      },
    ),
  }),
};
