import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const formsViewerCommunity: ThemeUICSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  '.field__content': {
    whiteSpace: 'pre-wrap',
  },
};
