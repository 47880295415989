import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const searchBox: ThemeUICSSObject = {
  /* PREPEND HERE */
  modal: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    minWidth: 'min(85vw, 400px)',
  },
};
