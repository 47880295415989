import { ThemeUICSSObject } from '@apia/theme';

const campaign: ThemeUICSSObject = {
  a: {
    div: {
      display: 'inline-flex',
    },
    ':hover': {
      cursor: 'pointer',
    },
  },
};

export { campaign };
