import { ApiaUtil } from '@apia/components';
import { createRoot } from 'react-dom/client';
import {
  ApiaThemeProvider,
  Theme,
  TCustomPalette,
  TThemeModifier,
} from '@apia/theme';
import { StoreProvider } from '@apia/store';
import { getLabel, useMount } from '@apia/util';
import Render from './Render';
import { setApplication } from './setApplication';
import customTheme from '../styles/customTheme';
import merge from 'lodash-es/merge';
import { ApiaApiHandler } from '@apia/api';
import '../custom/react-pivottable/pivottable.css';

declare global {
  interface Window {
    ApiaUtil: ApiaUtil;
    customTheme: () => Theme;
    themeModifier?: TThemeModifier;
  }
}

const apiaUtil = ApiaUtil.instance;
window.ApiaUtil = apiaUtil;

const Index = () => {
  useMount(() => {
    setApplication();
  });

  const mergeTheme = merge({}, customTheme, window.customTheme());
  return (
    <StoreProvider>
      <ApiaThemeProvider
        customPalette={() =>
          window.customPalettes?.reduce(
            (result, current) => ({ ...result, ...current }),
            {} as TCustomPalette,
          )
        }
        customTheme={() => mergeTheme}
        themeModifier={window.themeModifier}
      >
        <Render />
        <apiaUtil.Component />
        <ApiaApiHandler />
      </ApiaThemeProvider>
    </StoreProvider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
if ((window as any).USE_REACT === false) {
  alert(getLabel('lblReactRequired').text);
} else {
  const root = createRoot(document.getElementById('root') as HTMLElement);
  root.render(<Index />);
}
