import { ThemeUICSSObject } from '@apia/theme';

const remoteApia: ThemeUICSSObject = {
  display: 'flex',
  justifyContent: 'center',
  ':hover': {
    cursor: 'pointer',
  },
};

export { remoteApia };
