import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const itemDetails: ThemeUICSSObject = {
  table: {
    borderSpacing: 4,
  },

  th: {
    textAlign: 'left',
    width: '25%',
  },

  '.confirm__customButtonsOnRight button': {
    variant: 'buttons.outline-sm',
  },
};
