import { eventsController } from '@apia/dashboard-controller';
import { defaultNotifier, notify } from '@apia/notifications';
import { SessionStatus, session } from '@apia/session';
import { Url, debugDispatcher, shortcutController } from '@apia/util';
import { validationsStore } from '@apia/validations';

declare global {
  interface Window {
    notify: typeof notify;
    validations: typeof validationsStore;
  }
  interface HTMLIFrameElement {
    handleDeadSession?: () => unknown;
    onFocusTab: () => unknown;
    onTabFocus: () => unknown;
  }
}

export function setApplication() {
  session.on('change', (status) => {
    if (status === SessionStatus.Dead) {
      (window.frameElement as HTMLIFrameElement)?.handleDeadSession?.();
    }
  });

  window.notify = notify;
  window.validations = validationsStore;

  document.addEventListener('keydown', (ev) => {
    if (ev.code === 'Escape') {
      defaultNotifier.closeAll();
    }
    if (ev.key === 'F5' && window.location.href.match(/&develop=true($|&)/)) {
      ev.preventDefault();
      ev.stopPropagation();
      console.clear();
      // eslint-disable-next-line no-self-assign
      window.location = window.location;
    }
  });

  if (window.frameElement as HTMLIFrameElement) {
    (window.frameElement as HTMLIFrameElement).onFocusTab = () => {
      eventsController.broadcast('dashboard__focus', null);
    };
    const bodyController = document.querySelector(
      '#bodyController',
    ) as unknown as {
      onTabFocus: () => unknown;
    };
    if (bodyController)
      bodyController.onTabFocus = () => {
        eventsController.broadcast('dashboard__focus', null);
      };
  }

  debugDispatcher.on(
    'develop',
    (isDevelop) => {
      const newUrl = new Url(window.location.href, false);
      newUrl.addParameter('develop', isDevelop);

      // eslint-disable-next-line no-restricted-globals
      location.href = newUrl.toString();
    },
    'Acepta un booleano que establece el modo desarrollo de la aplicación.',
  );

  shortcutController.on(['shift&D', ...'dev'.split(''), 'shift&!'], () => {
    debugDispatcher.emit('develop', true);
  });
  shortcutController.on(
    'prod'.split(''),
    () => {
      debugDispatcher.emit('develop', false);
    },
    'dev',
  );
}
