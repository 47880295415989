import { ThemeUICSSObject, responsive } from '@apia/theme';
import { itemDetails } from './itemDetails';
import { spacing, getColorsAndStatesByPath } from '@apia/theme';
/* IMPORT HERE */

export const kblist: ThemeUICSSObject = {
  itemDetails,

  display: 'flex',
  flexDirection: 'column',
  gap: spacing(3),

  a: {
    textDecoration: 'none',
    color: 'palette.text.primary',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  '.kblist__item__code__wrapper': {
    flexShrink: 0,
    display: 'inline',

    '&, & *': {
      fontWeight: 'bold',
    },
  },

  '.kblist__item': {
    display: 'flex',
  },

  '.kblist__item__code': {
    p: 0,
  },

  'button.kblist__item__label, button.kblist__item__code': {
    textAlign: 'left',

    '&:not(:disabled, [readonly])': {
      '&:hover, &:active, &:focus': {
        backgroundColor: 'transparent',
        outline: 'none',
      },
    },
  },

  '.kblist__item__label': {
    flexShrink: 1,
  },

  '&.pagination': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '.pagination__controls': {
      justifyContent: 'center',
      flexShrink: 0,
      '&.onlyRefresh': {
        justifyContent: 'end',
      },
      '.pagination__selectPage': {
        padding: 0,
      },
      alignItems: 'stretch',
      padding: 1,
      display: 'flex',
      width: responsive({ 0: 'max-content', 2: 'auto' }),
      select: {
        textAlign: 'center',
      },
      'select ~ svg': {
        display: 'none',
      },
    },
    '.pagination__grid': {
      maxHeight: '300px',
      overflow: 'scroll',
      overflowX: 'hidden',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      scrollbarWidth: 'none',
    },
    '.pagination__grid::-webkit-scrollbar': {
      display: 'none',
    },
    '.pagination__button': {
      textDecoration: 'none',
      border: 'none !important',
      width: '32px',
      height: '32px',
      '.button__content': {
        paddingX: spacing(2),
      },
    },
    '.pagination__currentPage': {
      ...getColorsAndStatesByPath('buttons.iconOutline.hover'),
    },
  },

  '.kblist__filters': {
    display: 'flex',
    gap: spacing(5),
  },
  '.spinner': {
    alignSelf: 'center',
  },
  /* PREPEND HERE */
};
