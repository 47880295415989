import { ThemeUICSSObject } from '@apia/theme';
import { login } from './login';
import { functionalities } from './functionalities';
import { iconsFunctionalities } from './iconsFunctionalities';
import { taskList } from './taskList';
import { taskInfo } from './taskInfo';
import { campaign } from './campaign/campaign';
import { remoteApia } from './remoteApia/remoteApia';
import { menuLandingPageIndex } from './menuLandingPage';
import { common } from './common';
import { links } from './links';
import { widget } from './widget';

/* IMPORT HERE */

export const apia: ThemeUICSSObject = {
  campaign,
  functionalities,
  iconsFunctionalities,
  login,
  links,
  menuLandingPageIndex,
  taskList,
  taskInfo,
  remoteApia,
  common,
  widget,
  /* PREPEND HERE */
};
