import { spacing } from '@apia/theme';
import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const statisticsItems: ThemeUICSSObject = {
  /* PREPEND HERE */
  gap: spacing(4),
  display: 'flex',
  flexDirection: 'column',

  '.statisticsItems__table': {
    width: '100%',
  },

  '.statisticsItems__footer': {
    display: 'flex',
    alignItems: 'end',
  },
};
