import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const formsViewer: ThemeUICSSObject = {
  width: '100%',
  border: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  h1: {
    fontSize: '20px',
    color: 'palette.text.primary',
  },
  '.form__viewer__fields': {
    pl: 4,
  },
};
