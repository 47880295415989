import { ThemeUICSSObject } from '@apia/theme';

export const listbox: ThemeUICSSObject = {
  '.withSearchbox.listbox .listbox__list.messages__listbox': {
    maxHeight: '40vh',
    overflow: 'auto',
    pt: 3,
  },
  '.horizontal': {
    ul: {
      display: 'flex',
      gap: 3,
    },
  },

  '.listbox__row': {
    wordWrap: 'break-word',

    header: {
      fontWeight: 'bold',
    },
    p: {
      m: 0,
      p: 0,
    },
  },

  '.separator': {
    '&::before': {
      display: 'block',
      content: '""',
      mt: 3,
      pt: 3,
    },
  },

  '.vertical.nav__list': {
    '.listbox__list': {
      flexDirection: 'column',
    },
  },
};
