import { ThemeUICSSObject } from '@apia/theme';
import { baseList } from './documentsList';
/* IMPORT HERE */

export const commentsList: ThemeUICSSObject = {
  ...baseList,

  '.commentsList__comment form': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    gap: 3,

    '&:focus-within': {
      '.commentsList__comment__buttonsRow': {
        display: 'flex',
      },
    },
  },

  '.fieldWrapper': {
    width: '100%',
  },

  textarea: {
    width: '100%',
    minHeight: 0,
    maxHeight: '200px',
    py: 3,
  },
  '.commentAdded': {
    textArea: {
      resize: 'none',
    },
  },

  '.commentsList__comment__buttonsRow': {
    alignItems: 'end',
    gap: 3,
    display: 'none',
  },

  '.addCommentButton': {
    alignSelf: 'end',
  },
};
