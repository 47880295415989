import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const qnaPanel: ThemeUICSSObject = {
  width: '100%',
  px: '20px',
  py: '10px',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  form: {
    width: '100%',
  },
  '.qna__questions__container': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    justifyContent: 'center',
    alignContent: 'center',
    input: {
      maxWidth: '400px',
    },
    select: {
      maxWidth: '400px',
    },
  },
  '.qna__comments__container': {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    '.qna__comment': {
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
      p: 3,
      border: '1px solid',
      borderColor: 'palette.gray.800',
      borderRadius: '8px',
    },
    '.qna__comments__header': {
      display: 'flex',
      flexDirection: 'row',
      gap: 3,
    },
    textarea: {
      border: '1px solid',
      borderColor: 'palette.gray.800',
    },
  },

  '.qna__footer': {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    width: 'fit-content',
  },
};
