import { Theme, ThemeUICSSObject, spacing } from '@apia/theme';
/* IMPORT HERE */

export const rankingUsers: (theme: Theme) => ThemeUICSSObject = (theme) => {
  return {
    /* PREPEND HERE */
    li: {
      boxShadow: 'inset rgba(0, 0, 0, 0.15) 0px 0.95px 2.6px',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: 'auto',
      gap: spacing(5),
      py: '5px',
      px: '20px',
      alignItems: 'center',
      ':nth-of-type(odd)': {
        backgroundColor: theme.palette.secondary.light,
      },

      img: {
        height: '40px',
        width: '40px',
        flexShrink: 0,
      },

      '.activeUser': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        gap: '5px',
        width: '65%',
        h6: { whiteSpace: 'normal' },
      },
      '.activeUser__stats': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        width: '20%',
      },
    },
  };
};
