import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const starsRating: ThemeUICSSObject = {
  display: 'flex',
  gap: 1,

  '& > .star': {
    height: 'iconSm',
    width: 'iconSm',
  },

  button: {
    border: 'none',
    background: 'none',
  },

  '.filled__star': {
    svg: {
      color: 'favorite',
      path: {
        fill: 'favorite',
      },
    },
  },

  '.empty__star': {
    svg: {
      color: '#333',
      path: {
        fill: '#333',
      },
    },
  },

  '.half__star__container': {
    position: 'relative',

    '& > *': {
      position: 'absolute',
      left: 0,
      top: 0,
    },
  },

  '&.disabled': {
    'button.star': {
      '&:hover, &:focus, &:active': {
        background: 'none',
        color: 'palette.text.primary',
      },
    },
  },
};
