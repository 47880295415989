import { ThemeUICSSObject } from '@apia/theme';

export const messages: ThemeUICSSObject = {
  '& .listbox__list.messages__listbox': {
    listStyle: 'square',
    ml: '16px',

    '.listbox__row': {
      overflow: 'visible',
      whiteSpace: 'break-spaces',
      div: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        p: 2,
        ':hover': {
          '.icon': {
            opacity: 1,
          },
        },
        '.icon': {
          opacity: 0,
          transition: 'opacity 0.3s',
          color: 'palette.text.primary',
        },
      },
    },
  },

  '.separator': {
    listStyle: 'none',
  },

  '.messages__missingSections': {
    pt: 3,
    mt: 3,
  },
};
