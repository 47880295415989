import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const modulesTree: ThemeUICSSObject = {
  maxHeight: '80vh',
  overflow: 'auto',
  gap: 5,
  '.modulesTree__container': {
    display: 'flex',
    gap: 2,
    flexDirection: 'column',
  },
  h5: {
    fontSize: '17px',
    top: 0,
    position: 'sticky',
    background: 'palette.background.paper',
  },
  '.modulesTree__list': {
    pl: 5,
  },
  '.collapsiblePanel__label__button': {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'start',
    gap: 2,
  },
  '.title': {
    fontWeight: '600',
    ml: '-6px',
  },
  /* PREPEND HERE */
};
