import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const links: ThemeUICSSObject = {
  /* PREPEND HERE */
  display: 'grid',
  '.spinner': {
    justifySelf: 'center',
  },
};
