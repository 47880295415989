import { ThemeUICSSObject } from '@apia/theme';
import { siteMap } from './siteMap';
import { statisticsItems } from './statisticsItems';
import { searchBox } from './searchBox';
import { documentsList } from './documentsList';
import { commentsList } from './commentsList';
import { formsViewer } from './formsViewer';
import { qnaPanel } from './qnaPanel';
import { rankingUsers } from './rankingUsers';
import { questionsAndComments } from './questionsAndComments';
import { title } from './title';
import { formsViewerCommunity } from './formsViewerCommunity';
/* IMPORT HERE */

export const kb: ThemeUICSSObject = {
  searchBox,
  documentsList,
  commentsList,
  formsViewer,
  formsViewerCommunity,
  qnaPanel,
  rankingUsers,
  questionsAndComments,
  /* PREPEND HERE */
  siteMap,
  statisticsItems,
  title,
};
