import { ThemeUICSSObject } from '@apia/theme';
import { starsRating } from './starsRating';
import { kblist } from './kblist';
import { autoscrollContainer } from './autoscrollContainer';
/* IMPORT HERE */

export const components: ThemeUICSSObject = {
  starsRating,
  kblist,
  autoscrollContainer,
  /* PREPEND HERE */
};
