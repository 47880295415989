import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const widgets: ThemeUICSSObject = {
  /* PREPEND HERE */

  display: 'flex',
  justifyContent: 'center',
  '.widget_wrapper': {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
  },
  '&.widget__container': {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  '.widget__buttonContainer': {
    flexDirection: 'column',
    position: 'absolute',
    left: `0px`,
  },
  '.widget__defaultValue': {
    fontSize: '80px',
    color: 'palette.text.disabled',
  },
  '.widgetContainer__Counter': {
    position: 'relative',
  },
};
