import { spacing, responsive, ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const tableList: ThemeUICSSObject = {
  /* PREPEND HERE */
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(3),

  '.listPanel__details': {
    gridColumn: responsive({
      0: '1 / span 2',
      5: '1 / span 4',
    }),
  },

  '.selection__keyHandler': {
    maxHeight: '70vh',
    mx: 4,
  },

  '.responsiveTable__table': {
    variant: 'layout.common.tables.clean',
  },

  '.accordion': {
    variant: 'layout.common.components.accordion.panels',
    maxHeight: '70vh',
  },
};
