import { LandingPagePanelWrapper, Dashboard } from '@apia/dashboard-controller';
import { initValidations } from '@apia/validations';

void initValidations();
const dashboard = new Dashboard(
  window.top?.CURRENT_USER_LOGIN !== 'guest' &&
  window.top?.CURRENT_USER_LOGIN !== undefined
    ? undefined
    : LandingPagePanelWrapper,
  'apia',
);

declare global {
  interface Window {
    dashboard: Dashboard;
  }
}
window.dashboard = dashboard;

const Render = () => {
  return (
    <>
      <dashboard.Component />
    </>
  );
};

export default Render;
