import { ThemeUICSSObject } from '@apia/theme';
import { searchModal } from './searchModal';
import { search } from './search';
import { menuBar } from './menuBar';
import { itemsList } from './itemsList';
import { itemDetails } from './itemDetails';
/* IMPORT HERE */

export const spakb: ThemeUICSSObject = {
  searchModal,
  search,
  menuBar,
  itemsList,
  itemDetails,
  /* PREPEND HERE */
};
