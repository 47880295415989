import { Theme } from '@apia/theme';
import { layout } from './layout';
// import './fonts.css';
/* IMPORT HERE */

const customTheme: Omit<Theme, 'palette'> = {
  layout: layout as any,
  /* PREPEND HERE */
};

export default customTheme;
