import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const charts: ThemeUICSSObject = {
  /* PREPEND HERE */

  display: 'flex',
  justifyContent: 'center',
  '.ChartRenderer__Wrapper': {
    width: '100%',
  },
  '&.chart__container': {
    height: '100%',
    width: '100%',
  },

  '.chart__container__nodata': {
    textAlign: 'left',
    width: '100%',
  },
};
