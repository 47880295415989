import { spacing } from '@apia/theme';
import { ThemeUICSSObject } from 'theme-ui';

export const widget: ThemeUICSSObject = {
  commentModal: {
    '.confirm__content': {
      overflowY: 'auto',
      maxHeight: '300px',
    },
    '.collector__fields': {
      overflow: 'hidden',
    },
    '.comment_container': {
      paddingY: spacing(2),
    },
    '.comment_user': {
      opacity: 0.7,
    },
    '.comment_supervised_value': (theme) => {
      return {
        ...theme.palette.getOneState(
          { backgroundColor: 'palette.background.paper' },
          'selected',
        ),
        p: spacing(3),
        marginLeft: spacing(3),
      };
    },
    '.comment_value': () => {
      return {
        backgroundColor: 'palette.background.default',
        p: spacing(3),
        marginLeft: spacing(3),
      };
    },
  },
  footerButtons: {
    display: 'flex',
    gap: 1,
    flexShrink: 0,

    opacity: 0,
    transition: 'opacity 200ms',

    '&:is(.panel__container:hover *)': {
      opacity: 1,
    },
  },
};
