import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const baseList: ThemeUICSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  alignItems: 'stretch',
};

export const documentsList: ThemeUICSSObject = {
  ...baseList,

  '.documentsList__document': {
    border: '1px solid',
    borderColor: 'palette.border.section',
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    p: 5,
  },

  '.documentsList__document__dataRow': {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
  },

  a: {
    alignItems: 'center',
    display: 'inline-flex',
    gap: 2,
    color: 'palette.text.primary',
  },

  '.documentsList__document__dataItem': {
    alignItems: 'center',
    display: 'flex',
    gap: 3,
  },

  '.documentsList__document__dataItemLabel': {
    fontWeight: 'bold',
  },
};
