import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const userWorkResume: ThemeUICSSObject = {
  /* PREPEND HERE */

  display: 'flex',
  flexDirection: 'column',
  gap: 3,
};
