import { ThemeUICSSObject } from '@apia/theme';
import { components } from './components';
import { messages } from './messages';
import { apia } from './apia';
import { kb } from './kb';
import { gpt } from './gpt';
/* IMPORT HERE */

export const panels: ThemeUICSSObject = {
  apia,
  components,
  messages,
  kb,
  gpt,
  /* PREPEND HERE */
};
