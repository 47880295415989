import { ThemeUICSSObject } from '@apia/theme';

export const menuBar: ThemeUICSSObject = {
  display: 'flex',

  menuItem: {
    background: 'palette.background.paper',
    color: 'palette.text.primary',
    display: 'block',
    p: 3,
    textDecoration: 'none',
    whiteSpace: 'nowrap',

    '&.menuBar__item--hover, &:hover': (theme) => ({
      ...theme.palette.getOneState(
        { backgroundColor: theme.palette.background.paper },
        'hover',
      ),
    }),
  },
};
