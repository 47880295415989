import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const form: ThemeUICSSObject = {
  alignItems: 'end',
  display: 'flex',
  flexDirection: 'column',
  gap: 3,

  form: {
    alignItems: 'end',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },

  textarea: {
    height: '150px',
  },

  '.gpt__buttonsContainer': {
    display: 'flex',
    alignItems: 'end',
    gap: 3,
  },

  ul: {
    ml: 6,
    listStyle: 'square',
  },

  li: { mb: 4 },
};
