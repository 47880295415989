import { spacing } from '@apia/theme';
import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const siteMap: ThemeUICSSObject = {
  /* PREPEND HERE */
  display: 'grid',
  gap: spacing(5),

  '.sitemap__panel__body': {
    display: 'flex',
    gap: spacing(5),
    justifyContent: 'space-between',
  },

  '.sitemap__panel__body__info': {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(5),
  },

  '.sitemap__panel__body__image': {
    flexShrink: 0,
  },

  '.sitemap__panel__body__availablePanel': {
    listStyle: 'square',
    ml: '16px',
  },

  '.sitemap__panel__body__availablePanelsLabel': {
    fontStyle: 'italic',
  },

  '.sitemap__header': {
    a: {
      font: 'inherit',
      color: 'inherit',
      textDecoration: 'inherit',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
};
