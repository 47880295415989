import { ThemeUIStyleObject } from '@apia/theme';
import { panels } from './panels';
import { tests } from './tests';
import { common } from './common';
import { spakb } from './spakb';
/* IMPORT HERE */

export const layout: Record<string, ThemeUIStyleObject> = {
  panels,
  tests,
  common,
  spakb,
  /* PREPEND HERE */
};
