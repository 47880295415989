import { spacing } from '@apia/theme';
import { ThemeUICSSObject } from '@apia/theme';

const loginForm: ThemeUICSSObject = {
  backgroundColor: 'white',
  border: '1px solid',
  borderColor: 'palette.gray.800',
  h1: {
    mb: spacing(3),
  },
  p: spacing(6),
  '.loginForm__container': {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  '.fieldWrapper': {
    span: {
      pb: spacing(3),
      fontWeight: 'bold',
    },
  },

  '.loginForm__footer': {
    button: {
      padding: '5px !important',
      boxSizing: 'border-box',
      margin: '0px',
      appearance: 'none',
      display: 'inline-block',
      lineHeight: 'inherit',
      fontSize: 'inherit',
      borderStyle: 'none',
      borderWidth: 'medium',
      borderImage: 'none 100% / 1 / 0 stretch',
      textDecoration: 'underline',
      borderRadius: '0px',
      cursor: 'pointer',
      textTransform: 'none',
      transition: 'background-color 300ms ease-out 0s, color 300ms ease-out 0s',
      color: 'var(--theme-ui-colors-buttons-link-color)',
      backgroundColor: 'var(--theme-ui-colors-buttons-link-backgroundColor)',
      borderColor: 'palette.border.field',
      width: '100% !important',
      textAlign: 'start',
      ':hover': {
        backgroundColor: '#f0f0f0 !important',
        color: 'var(--theme-ui-colors-buttons-link-color) !important',
      },
    },
  },
  '.requiredMark': {
    textDecoration: 'none !important',
    color: 'palette.error.main',
  },
};

export { loginForm };
