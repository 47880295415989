import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const error: ThemeUICSSObject = {
  /* PREPEND HERE */
  display: 'flex',
  gap: 3,
  justifyContent: 'space-around',

  '.error__message': {
    width: 'min-content',
  },

  '.error__button': (theme) => ({
    ...theme.palette.getColorStates({
      backgroundColor: 'palette.error.dark',
      color: 'palette.error.contrastText',
      borderColor: 'palette.error.dark',
    }),
  }),
};
