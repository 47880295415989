import { ThemeUICSSObject } from '@apia/theme';

const icons: ThemeUICSSObject = {
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
  '.noIcons': {
    display: 'block !important',
    alignItems: 'start',
    textAlign: 'start',
    height: '100%',
    p: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '.icons__moreFuncs': {
    color: 'palette.gray.600',
  },
  '.iconsList__spinner': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};

export { icons };
