import { spacing } from '@apia/theme';
import { ThemeUICSSObject } from '@apia/theme';

export const taskInfo: ThemeUICSSObject = {
  '.tskCount': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
    textAlign: 'center',
    pb: spacing(3),
  },
  '.tskIconButtons': {
    gap: spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
    button: {
      border: 'none',
      borderRadius: '50%',
    },
  },
};
