import { spacing } from '@apia/theme';
import { ThemeUICSSObject } from '@apia/theme';

const langs: ThemeUICSSObject = {
  display: 'flex',
  alignItems: 'end',
  flexDirection: 'column',
  position: 'fixed',
  top: 0,
  right: 0,
  width: '200px',
  mr: '10px',
  zIndex: 2,
  '.langs__selectLangButtonContainer': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    mt: spacing(3),
    span: {
      pr: 2,
    },
  },
  li: {
    listStyle: 'none',
    ':focus': {
      backgroundColor: '#f0f0f0 !important',
      color: 'var(--theme-ui-colors-buttons-link-color) !important',
      outline: 'none',
    },
  },
  '.langs__openMenuBtn': {
    color: 'palette.primary.main',
    textAlign: 'center',
    width: 'fit-content',
    height: 'fit-content',
    padding: '5px !important',
    boxSizing: 'border-box',
    margin: '0px',
    appearance: 'none',
    display: 'inline-block',
    lineHeight: 'inherit',
    fontSize: 'inherit',
    borderStyle: 'none',
    borderWidth: 'medium',
    borderImage: 'none 100% / 1 / 0 stretch',
    borderRadius: '0px',
    cursor: 'pointer',
    textTransform: 'none',
    transition: 'background-color 300ms ease-out 0s, color 300ms ease-out 0s',
    backgroundColor: 'var(--theme-ui-colors-buttons-link-backgroundColor)',
    borderColor: 'palette.border.field',
    svg: {
      width: '24px',
      height: '24px',
    },
    span: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    ':hover': {
      backgroundColor: '#eaeaec !important',
      color: 'var(--theme-ui-colors-buttons-link-color) !important',
    },
    ':focus': {
      backgroundColor: '#f0f0f0 !important',
      color: 'var(--theme-ui-colors-buttons-link-color) !important',
    },
  },
  '.langs__menu': {
    height: 'auto',
    width: 'auto',
    maxHeight: '55vh',
    overflow: 'auto',
    m: 0,
    border: '1px solid',
    borderColor: 'palette.border.section',
    backgroundColor: 'white',
    p: 0,
  },
  '.langs__langBtn': {
    backgroundColor: 'white',
    my: spacing(2),
    padding: '5px !important',
    boxSizing: 'border-box',
    margin: '0px',
    appearance: 'none',
    display: 'inline-block',
    lineHeight: 'inherit',
    fontSize: 'inherit',
    borderStyle: 'none',
    borderWidth: 'medium',
    borderImage: 'none 100% / 1 / 0 stretch',
    borderRadius: '0px',
    cursor: 'pointer',
    textTransform: 'none',
    transition: 'background-color 300ms ease-out 0s, color 300ms ease-out 0s',
    color: 'var(--theme-ui-colors-buttons-link-color)',
    borderColor: 'palette.border.field',
    width: '100% !important',
    textAlign: 'start',
    ':hover': {
      backgroundColor: '#f0f0f0 !important',
      color: 'var(--theme-ui-colors-buttons-link-color) !important',
    },
    ':focus': {
      backgroundColor: '#f0f0f0 !important',
      color: 'var(--theme-ui-colors-buttons-link-color) !important',
    },
    span: {
      px: '2.2rem',
    },
  },
};

export { langs };
