import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const questionsAndComments: ThemeUICSSObject = {
  /* PREPEND HERE */
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  '.questionsAndComment__container': {
    p: '20px',
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    border: '1px solid',
    borderColor: 'palette.gray.800',
    borderRadius: '8px',
  },
  a: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  '.questionsAndComment__questionImage': {
    width: '40px',
    height: '40px',
    flexShrink: 0,
  },
  '.questionsAndComment__content': {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  '.questionsAndComment__description': {
    overflow: 'auto',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    span: {
      backgroundColor: 'palette.gray.900',
      borderRadius: '20px',
      p: '5px 10px',
    },
    '.questionsAndComment__answerImage': {
      width: '20px',
      height: '20px',
      flexShrink: 0,
    },
  },
};
