import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const iframes: ThemeUICSSObject = {
  default: {
    border: 'none',
  },

  /* PREPEND HERE */
};
