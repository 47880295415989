import { ThemeUICSSObject } from '@apia/theme';
/* IMPORT HERE */

export const remoteTasks: ThemeUICSSObject = {
  /* PREPEND HERE */

  '.spinner': {
    display: 'flex',
    justifyContent: 'center',
  },
};
